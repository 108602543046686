import { setPagePath } from 'helpers/routes';
import { PathType, Pages, NavType, PageType, LinkType } from 'types/routes';

export const PATHS: PathType = {
	home: {
		root: '/',
		name: Pages.home,
		seo: {
			title: '',
			description: '',
		},
	},
	about: {
		root: setPagePath('about'),
		name: Pages.about,
		seo: {
			title: 'About company | Vyatka Wood House',
			description: '',
		},
		isHeader: true,
	},
	catalog: {
		root: setPagePath('catalog'),
		name: Pages.catalog,
		seo: {
			title: '',
			description: '',
		},
		isHeader: true,
	},
	gallery: {
		root: setPagePath('gallery'),
		name: Pages.gallery,
		seo: {
			title: 'Gallery | Vyatka Wood House',
			description: '',
		},
		isHeader: true,
	},
	services: {
		root: setPagePath('services'),
		name: Pages.services,
		seo: {
			title: '',
			description: '',
		},
		isHeader: true,
		submenu: [
			{
				link: setPagePath('services/production'),
				name: Pages.production,
			},
			{
				link: setPagePath('services/designing'),
				name: Pages.designing,
			},
			{
				link: setPagePath('services/building'),
				name: Pages.building,
			},
			{
				link: setPagePath('services/engineering'),
				name: Pages.engineering,
			},
			{
				link: setPagePath('services/engineering'),
				name: Pages.finishingWorks,
			},
			{
				link: setPagePath('services/after-sales-service'),
				name: Pages.afterSalesService,
			},
			{
				link: setPagePath('services/landscape'),
				name: Pages.landscape,
			},
			{
				link: setPagePath('services/legacy'),
				name: Pages.legacy,
			},
		],
	},
	production: {
		root: setPagePath('services/production'),
		name: Pages.production,
		seo: {
			title: '',
			description: '',
		},
	},
	designing: {
		root: setPagePath('services/designing'),
		name: Pages.designing,
		seo: {
			title: '',
			description: '',
		},
	},
	building: {
		root: setPagePath('services/building'),
		name: Pages.building,
		seo: {
			title: '',
			description: '',
		},
	},
	engineering: {
		root: setPagePath('services/engineering'),
		name: Pages.engineering,
		seo: {
			title: '',
			description: '',
		},
	},
	finishingWorks: {
		root: setPagePath('services/engineering'),
		name: Pages.finishingWorks,
		seo: {
			title: '',
			description: '',
		},
	},
	afterSalesService: {
		root: setPagePath('services/after-sales-service'),
		name: Pages.afterSalesService,
		seo: {
			title: '',
			description: '',
		},
	},
	landscape: {
		root: setPagePath('services/landscape'),
		name: Pages.landscape,
		seo: {
			title: '',
			description: '',
		},
	},
	legacy: {
		root: setPagePath('services/legacy'),
		name: Pages.legacy,
		seo: {
			title: '',
			description: '',
		},
	},
	contacts: {
		root: setPagePath('contacts'),
		name: Pages.contacts,
		seo: {
			title: 'Contact us | Vyatka Wood House',
			description: '',
		},
		isHeader: true,
	},
};

export const navigation: NavType[] = Object.values(PATHS)
	.filter((item) => item.isHeader)
	.map((path: PageType & LinkType) => {
		const option: NavType = {
			name: path.name,
			link: path.root,
		};
		if (path.submenu) {
			option.submenu = path.submenu;
		}
		return option;
	});
