import { Fragment, lazy, Suspense } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';

import { PATHS } from './paths';

export const Loadable = (Component: any) => (props: any) =>
	(
		<Suspense fallback={<></>}>
			<Component {...props} />
		</Suspense>
	);

const Layout = Loadable(lazy(() => import('layout/Page/Main')));
const Main = Loadable(lazy(() => import('pages/Home')));
const Contacts = Loadable(lazy(() => import('pages/Contacts')));

const Catalog = Loadable(lazy(() => import('pages/Catalog')));
const CatalogLayout = Loadable(lazy(() => import('layout/Catalog/Layout')));
const Card = Loadable(lazy(() => import('layout/Catalog/Card')));

const Services = Loadable(lazy(() => import('pages/Services')));
const Production = Loadable(lazy(() => import('pages/Production')));
const AfterSaleService = Loadable(lazy(() => import('pages/AfterSaleService')));
const Building = Loadable(lazy(() => import('pages/Building')));
const Landscape = Loadable(lazy(() => import('pages/Landscape')));
const Engineering = Loadable(lazy(() => import('pages/Engineering')));
const Legacy = Loadable(lazy(() => import('pages/Legacy')));
const Designing = Loadable(lazy(() => import('pages/Designing')));
const About = Loadable(lazy(() => import('pages/About')));
const Gallery = Loadable(lazy(() => import('pages/Gallery')));
const Page404 = Loadable(lazy(() => import('pages/404')));

const ROUTES: RouteObject[] = [
	{
		path: PATHS.home.root,
		element: <Layout />,
		children: [
			{
				index: true,
				element: <Main />,
			},
			{
				path: PATHS.about.root,
				element: <About />,
			},
			{
				path: PATHS.contacts.root,
				element: <Contacts />,
			},
			{
				path: PATHS.catalog.root,
				element: <CatalogLayout />,
				children: [
					{
						index: true,
						element: <Catalog />,
					},
					{
						path: ':homeId',
						element: <Card />,
					},
				],
			},
			{
				path: PATHS.catalog.root,
				element: <Services />,
			},
			{
				path: PATHS.production.root,
				element: <Production />,
			},
			{
				path: PATHS.building.root,
				element: <Building />,
			},
			{
				path: PATHS.landscape.root,
				element: <Landscape />,
			},
			{
				path: PATHS.designing.root,
				element: <Designing />,
			},
			{
				path: PATHS.engineering.root,
				element: <Engineering />,
			},
			{
				path: PATHS.finishingWorks.root,
				element: <Engineering />,
			},
			{
				path: PATHS.afterSalesService.root,
				element: <AfterSaleService />,
			},
			{
				path: PATHS.legacy.root,
				element: <Legacy />,
			},
			{
				path: PATHS.gallery.root,
				element: <Gallery />,
			},
			{
				path: PATHS.contacts.root,
				element: <Contacts />,
			},
			{ path: '*', element: <Page404 /> },
		],
	},
];

const PageRoutes = () => {
	const routes = useRoutes(ROUTES);
	return <Fragment>{routes}</Fragment>;
};

export default PageRoutes;
