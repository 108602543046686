import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import PageRoutes from "routes/routes";

import "styles/index.scss";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<StrictMode>
		<BrowserRouter>
			<PageRoutes />
		</BrowserRouter>
	</StrictMode>
);
