export enum Pages {
	home = 'Главная',
	about = 'О нас',
	contacts = 'Контакты',
	catalog = 'Каталог',
	services = 'Услуги',
	production = 'Производство',
	designing = 'Проектирование',
	building = 'Строительство',
	engineering = 'Инженерные сети',
	finishingWorks = 'Отделочные работы',
	afterSalesService = 'Сервисное обслуживание',
	legacy = 'Юридическое сопровождение',
	gallery = 'Галерея',
	landscape = 'Ландшафтный дизайн ',
}

type SeoType = {
	title: string;
	description: string;
	noindex?: boolean;
};

export type PagesType = keyof typeof Pages;

export type PageType = {
	seo: SeoType;
};

export type LinkType<T = Pages> = {
	root: string;
	isFooter?: boolean;
	name: T;
	target?: boolean;
	rel?: boolean;
	isHeader?: boolean;
	submenu?: NavType[];
};

export type PathType<T = Pages> = {
	[key in PagesType]: PageType & LinkType<T>;
};

export type NavType = {
	name: string;
	link: string;
	state?: { [key: string]: string };
	submenu?: NavType[];
};
